export const dataSendToAndroid = (data) => {
    console.log("Sending data to Android/iOS and other React app:", JSON.stringify(data));
    
    if (window.androidObj) {
        window.androidObj.readId(JSON.stringify(data));
    }

    if (window.webkit && window.webkit.messageHandlers.readId) {
        window.webkit.messageHandlers.readId.postMessage(JSON.stringify(data));
    }


    window.parent.postMessage({ type: 'dataReceivedFromReact', payload: JSON.stringify(data) }, '*');
};
